<template>
    <div class="msg-table-tool-container">
        <div class="table-search-tool-container">
            <el-form class="msg-management-form" :inline="true" :model="formData">
                <el-form-item label="消息标题" style="marginRight: 20px;">
                    <el-input v-model="formData.msgTitle" placeholder="消息标题" size="small" style="width: 150px;"></el-input>
                </el-form-item>
                <!-- <el-form-item label="消息类型" style="marginRight: 20px;">
                    <el-select v-model="formData.msgType" placeholder="消息类型" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="公告" :value="1"></el-option>
                        <el-option label="告警" :value="2"></el-option>
                        <el-option label="工单" :value="3"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="消息状态">
                    <el-select style="width: 150px;" v-model="formData.readStatus"  placeholder="消息状态" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="已读" :value="1"></el-option>
                        <el-option label="未读" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                    <el-button @click="resetHandel">重置</el-button>
                </el-form-item>
                <el-form-item label="消息时间">
                    <el-date-picker
                        v-model="formData.msgTime"
                        size="small"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-operate-tool-container">
            <MsgSettingModal>
                <el-button type="primary" style="marginRight: 10px;">消息设置</el-button>
            </MsgSettingModal>
            <OperateModal :getData="getData">
                <el-button type="primary" style="marginRight: 10px;">新建公告</el-button>
            </OperateModal>
            <el-button type="primary" @click="reedMsgHandel">标记已读</el-button> 
        </div>
    </div>
</template>

<script>
import OperateModal from './OperateModal'
import MsgSettingModal from './MsgSettingModal';
import './TableTool.less';
import axios from '@/utils/axios';
import moment from 'moment';
export default {
    name: 'Table',
    components: {
        OperateModal,
        MsgSettingModal
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            formData: {
                msgTitle: '',
                msgType: '',
                readStatus: '',
                msgTime: ''
            }
        }
    },
    methods: {
        getData(_page) {
            const { page, pageSize } = this;
            const { 
                msgTime,
                msgTitle,
                msgType,
                readStatus,
            } = this.formData;
            let startTime = '';
            let endTime = '';
            if (msgTime) {
                const [ time1, time2 ] = msgTime;
                startTime = moment(time1).format('YYYY-MM-DD HH:mm:ss');
                endTime = moment(time2).format('YYYY-MM-DD HH:mm:ss');
            }
            axios.get(`/api/msgLog/pageList/${_page ? _page : page}/${pageSize}`, {
                msgTitle,
                msgType,
                readStatus,
                startTime,
                endTime
            }).then(rs => {
                this.$emit('dataChange', rs);
            }).catch(err =>  {
                this.$message.error(err);
            });
        },
        resetHandel() {
            this.formData = {
                msgTitle: '',
                msgType: '',
                readStatus: '',
                msgTime: ''
            };
        },
        reedMsgHandel() {
            this.$emit('batchRead')
        }
    },
    mounted() {
        this.getData();
    }
}
</script>