<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                :title="fillData.id ? '编辑公告' : '新建公告'"
                :visible.sync="visible"
                width="550px"
            >
                <div class="msg-form-container">
                    <el-form 
                        ref="createForm" 
                        label-width="80px" 
                        class="operate-form"
                        :model="formData"
                        :rules="rules"
                    >
                        <el-form-item label="消息标题" prop="msgTitle">
                            <el-input placeholder="消息标题" v-model="formData.msgTitle"></el-input>
                        </el-form-item>
                        <el-form-item label="消息内容" prop="msgContent">
                            <el-input
                                v-model="formData.msgContent"
                                type="textarea"
                                :rows="4"
                                placeholder="请输入内容"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发送方式" prop="sendWay" v-if="!fillData.id">
                            <el-checkbox-group v-model="formData.sendWay" :disabled="fillData.id">
                                <el-checkbox :label="1">站内信</el-checkbox>
                                <el-checkbox :label="2">公众号</el-checkbox>
                                <el-checkbox :label="3">短信</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="接受角色" prop="receiveRoleId" v-if="!fillData.id">
                            <el-select v-model="formData.receiveRoleId" placeholder="接受角色" :disabled="fillData.id">
                                <el-option label="运维人员" value="-1"></el-option>
                                <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios'
import './OperateModal.less';
export default {
    name: 'OperateModal',
    props: {
        getData: {
            type: Function,
            default: () => {}
        },
        fillData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            visible: false,
            checkList: ['站内信'],
            formData: {
                msgTitle: '',
                msgContent: '',
                sendWay: [],
                receiveRoleId: ''
            },
            roleList: [],
            rules: {
                msgTitle: [
                    { required: true, message: '请输入消息标题', trigger: 'blur' }
                ],
                msgContent: [
                    { required: true, message: '请输入消息内容', trigger: 'blur' }
                ],
                sendWay: [
                    { required: true, message: '请选择发送方式', trigger: 'blur' }
                ],
                receiveRoleId: [
                    { required: true, message: '请选择接受角色', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        changeVisible(flag) {
            if (this.fillData.id) {
                const {
                    msgTitle,
                    msgContent
                } = this.fillData;
                this.formData = {
                    msgTitle,
                    msgContent,
                    sendWay: [],
                    receiveRoleId: ''
                };
            }
            this.visible = flag;
            this.getOperateUserList();
        },
        getOperateUserList() {
            axios.get('/api/role/getList').then(rs => {
                this.roleList = rs.map(item => item);
            }).catch(err => {
                this.$message.error(err);
            });
        },
        submitHandel() {
            if (this.fillData.id) {
                // 编辑
                this.$refs.createForm.validate(valid => {
                    if (valid) {
                        const {
                            msgTitle,
                            msgContent
                        } = this.formData
                        const msgId = this.fillData.msgId;
                        axios.post('/api/msg/edit', {
                            msgTitle,
                            msgContent,
                            msgId
                        }).then(() => {
                            this.$message.success('编辑成功');
                            this.visible = false;
                            this.$refs.createForm.resetFields();
                            this.getData();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    }
                }); 
            } else {
                this.$refs.createForm.validate(valid => {
                    if (valid) {
                        const {
                            msgTitle,
                            msgContent,
                            sendWay,
                            receiveRoleId
                        } = this.formData
                        axios.post('/api/msg/create', {
                            msgTitle,
                            msgContent,
                            sendWay: sendWay.join(','),
                            receiveRoleId
                        }).then(() => {
                            this.$message.success('创建成功');
                            this.visible = false;
                            this.$refs.createForm.resetFields();
                            this.getData();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    }
                });
            }
        }
    }
}
</script>