<template>
    <div class="msg-management-contianer">
        <div class="table-tool">
            <TableTool ref="tableTool" :page="page" :pageSize="pageSize" @dataChange="dataChangeHandel" @batchRead="batchReadHandel"/>
        </div>
        <div class="table-content">
            <el-table 
                height="100%" 
                :data="list"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    :selectable="(row) => { return row.readStatus === 0 }"
                >
                </el-table-column>
                <el-table-column label="消息类型" prop="msgType">
                    <template v-slot="{row}">
                        {{ formatMsgType(row.msgType) }}
                    </template>
                </el-table-column>
                <el-table-column label="消息标题" prop="msgTitle"></el-table-column>
                <el-table-column label="消息内容" prop="msgContent"></el-table-column>
                <el-table-column label="发送时间" prop="createTime"></el-table-column>
                <el-table-column label="消息状态" prop="readStatus">
                    <template v-slot="{row}">
                        {{ row.readStatus === 0 ? '未读' : '已读' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="operate">
                    <template v-slot="{row}">
                        <a v-if="row.readStatus === 0" class="operate-a" @click="readMsgHandel([row.id])">标记已读</a>
                        <OperateModal :fillData="row" :getData="getData">
                            <a class="operate-a">编辑</a>
                        </OperateModal>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table-page">
            <div class="table-page-container">
                <el-pagination
                    background
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import TableTool from './components/TableTool'
import './Index.less';
import axios from '@/utils/axios';
import OperateModal from './components/OperateModal';
const msgTypeMap = {
    1: '公告',
    2: '告警',
    3: '工单'
};
export default {
    name: 'MsgManagement',
    components: {
        TableTool,
        OperateModal
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            list: [],
            total: 0,
            selectMsgIds: []
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.$refs.tableTool.getData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$refs.tableTool.getData();
        },
        dataChangeHandel(rs) {
            const {
                records,
                total
            } = rs;
            this.list = records;
            this.total = total;
        },
        handleSelectionChange(val) {
            this.selectMsgIds = val.map(item => item.id);
        },
        readMsgHandel(ids) {
            if (ids.length === 0) {
                this.$message.info('请选择消息');
                return;
            }
            this.$confirm('是否将选择的消息已读?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/msgLog/read/${ids.join(',')}`).then(
                    () => {
                        this.$message.success('操作成功');
                        this.$refs.tableTool.getData();
                    }
                ).catch(err => {
                    this.$message.error(err);
                });
            }).catch(() => {
            });
        },
        batchReadHandel() {
            this.readMsgHandel(this.selectMsgIds);
        },
        formatMsgType(msgType) {
            return msgTypeMap[msgType]
        },
        getData() {
            this.$refs.tableTool.getData()
        }
    }
}
</script>