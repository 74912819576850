<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="设置发送消息"
                :visible.sync="visible"
                width="550px"
            >
                <div class="msg-form-container">
                    <el-form 
                        ref="operateForm" 
                        label-width="80px" 
                        class="operate-form"
                    >
                        <el-form-item label="工单消息">
                            <el-checkbox-group v-model="formData[0].checkList">
                                <el-checkbox :label="1">站内信</el-checkbox>
                                <el-checkbox :label="2">公众号</el-checkbox>
                                <el-checkbox :label="3">短信</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="告警消息">
                            <el-checkbox-group v-model="formData[1].checkList">
                                <el-checkbox :label="1">站内信</el-checkbox>
                                <el-checkbox :label="2">公众号</el-checkbox>
                                <el-checkbox :label="3">短信</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './MsgSettingModal.less';
import axios from '@/utils/axios';
export default {
    name: 'MsgSettingModal',
    data() {
        return {
            visible: false,
            formData: [
                {
                    id: 13,
                    innerMsg: 0,
                    msgType: 1,
                    officialAccount: 0,
                    shortMessage: 0,
                    checkList: []
                },
                {
                    id: 14,
                    innerMsg: 0,
                    msgType: 2,
                    officialAccount: 0,
                    shortMessage: 0,
                    checkList: []
                },
            ]
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            this.getSettingInfo();
        },
        getSettingInfo() {
            axios.get('/api/msgSetting/getMsgSetting').then(rs => {
                const [set1, set2] = rs;
                const {
                    innerMsg,
                    officialAccount,
                    shortMessage,
                } = set1
                const checkList1 = [];
                if (innerMsg === 1) {
                    checkList1.push(1);
                }
                if (officialAccount === 1) {
                    checkList1.push(2);
                }
                if (shortMessage === 1) {
                    checkList1.push(3);
                }

                const {
                    innerMsg: innerMsg2,
                    officialAccount: officialAccount2,
                    shortMessage: shortMessage2,
                } = set2
                const checkList2 = [];
                if (innerMsg2 === 1) {
                    checkList2.push(1);
                }
                if (officialAccount2 === 1) {
                    checkList2.push(2);
                }
                if (shortMessage2 === 1) {
                    checkList2.push(3);
                }

                set1.checkList = checkList1;
                set2.checkList = checkList2;
                this.formData = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        submitHandel() {
            const [set1, set2] = this.formData;
            set1.innerMsg = set1.checkList.includes(1) ? 1 : 0
            set1.officialAccount = set1.checkList.includes(2) ? 1 : 0
            set1.shortMessage = set1.checkList.includes(3) ? 1 : 0

            set2.innerMsg = set2.checkList.includes(1) ? 1 : 0
            set2.officialAccount = set2.checkList.includes(2) ? 1 : 0
            set2.shortMessage = set2.checkList.includes(3) ? 1 : 0
            axios.post('/api/msgSetting/edit', this.formData).then(rs => {
                this.$message.success('设置成功');
                this.visible = false;
            }).catch(err => {
                this.$message.err(err);
            });
        }
    }
}
</script>